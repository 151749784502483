.App {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .ant-modal {
    position: static !important;
  }
}

@media screen and (max-width: 768px) {
  .ant-modal-body {
    padding: 0.5rem 2rem !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-btn {
  background-color: #a36b53 !important;
  color: white !important;
  border: 1px solid white !important;
}

.ant-btn:hover {
  background-color: #6a412f !important;
}

.ant-btn:focus {
  background-color: #6a412f !important;
}

.ant-input-search-button {
  background-color: #a36b53 !important;
  border: none !important;
}

.ant-input-search-button:hover {
  background-color: #a36b53;
}

.ant-input-search-button:focus {
  background-color: #a36b53;
}
.ant-segmented-item-label{
  white-space: break-spaces !important;
}
.ant-segmented-item-selected{
  background-color: #a36b53 !important;
  border-color: #a36b53 !important;
  color: white !important;
}
.ant-segmented-item-selected:hover{
  background-color: #6a412f !important;
  border-color: #6a412f !important;
  color: white !important;
}
.ant-segmented-item-selected:focus{
  background-color: #6a412f !important;
  border-color: #6a412f !important;
  color: white !important;
}

.ant-btn-ghost {
  color: rgba(0, 0, 0, .85) !important;
  border-color: #d9d9d9 !important;
  background: transparent !important;
}

.ant-btn-ghost:hover {
  color: rgba(0, 0, 0, .85) !important;
  border-color: #d9d9d9 !important;
  background: silver !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


@media print {
  @page {
    size: landscape;
  }

  #print .ant-table {
    width: 100%;
    table-layout: fixed;
  }

  #print .ant-table th, #print .ant-table td {
    padding: 8px;
    font-size: 0.8em;
  }
  .category-1d {
    background-color: #abcfec;
  }

  .category-2d {
    background-color: #f6d7dc;
  }

  .category-3d {
    background-color: #FFFFE0;
  }

  .category-4d {
    background-color: #c5dcc5;
  }

  .category-junior {
    background-color: #abcfec;
  }
}
