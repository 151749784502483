html body{
  overflow-y: scroll;
}


@media screen and (max-width: 768px) {
  .ant-modal {
    position: static !important;
  }
}

@media screen and (max-width: 768px) {
  .ant-modal-body {
    padding: 0.5rem 2rem !important;
  }
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .description {
    padding-left: 100px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 842px) {
  .headerMenu {
    display: none;
  }
}

.ant-layout-header {
  padding: 0 0px !important;
}

.ant-layout-content {
  padding: 0 50px !important;

  @media screen and (max-width: 476px) {
    padding: 0 10px !important;
  }
}


@media (min-width: 842px) {
  .menuIcon {
    display: none;
  }
}

.ant-table {
  display: block !important;
  overflow: scroll !important;
  @media screen and (max-width: 600px) {
    &-thead {
      display: none !important;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem !important;
        }

        &:last-of-type {
          padding-bottom: 1rem !important;
        }
      }

      >th,
      >td {
        display: block !important;
        width: auto !important;
        border: none !important;
        padding: 0 1rem !important;
        font-size: 1.1rem !important;

        &:last-child {
          border-bottom: 1px solid #eee !important;
        }
      }
    }
  }
}
